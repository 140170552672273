import styled from 'styled-components';
import poly2 from '../../../images/poly-bottom-right.svg';
import poly1 from '../../../images/poly-top-left.svg';
import Section from '../../../molecules/Section/Section';
import { colors } from '../../atoms/colors';
import shadow from '../../atoms/shadows';
import { font } from '../../atoms/typography';

export const NewsroomBody = styled.div`
  background: #f9fbfe;
`;

export const HeaderContainer = styled.div`
  background-color: var(--dark-color);
  background-image: url(${poly1}), url(${poly2});
  background-repeat: no-repeat;
  background-position: left top, right bottom;
  padding-bottom: 4px;
`;

export const NewsRoomSection = styled(Section)`
  margin: 0 auto;

  .subtitle {
    margin-bottom: 40px;
  }

  .newsroom-col {
    margin-bottom: 32px;
  }
`;

export const NewsRoomBottomCTA = styled.div`
  padding: 96px 0;

  .heading-title {
    margin-bottom: 24px;
  }

  .sub-head {
    margin-bottom: 48px;
  }
`;

export const NewsRoomCardStyles = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  background-color: ${colors.base.white};
  border-radius: 12px;
  ${shadow('xl')}
  cursor: pointer;
  overflow: hidden;

  &:hover {
    .card-image {
      img {
        transform: scale(1.1);
      }
    }
  }

  &.card-link {
    text-decoration: none;
    height: 100%;
  }

  .card {
    position: relative;
    border: none;
    height: 100%;
    min-height: 518px;
    overflow: hidden;
    cursor: pointer;

    &-image {
      figure {
        .gatsby-image-wrapper {
          height: 100%;
          min-height: 213px;

          img {
            display: block;
            object-fit: cover;
            object-position: center;
            transform-origin: center;
            transition: transform 300ms ease-in-out 0s;
          }
        }
      }
    }

    .card-image {
      height: 213px;

      @media (max-width: 768px) {
        height: auto;
      }

      & .default-image {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--primary-color);
        padding: 48px;
        height: 100%;
      }

      & img {
        width: 100%;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px;

      @media (min-width: 768px) and (max-width: 992px) {
        padding: 16px;
      }

      .tag-date-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .date {
          ${font('text', 'xs', '400')};
          color: ${colors.gray[600]};
          white-space: nowrap;
          padding-left: 16px;
        }
      }

      @media (max-width: 992px) {
        height: 50%;
      }

      .cat-link {
        pointer-events: all;
        position: relative;
        text-decoration: none;
      }

      .brand-logo {
        height: 25px;
        margin-top: 32px;

        img {
          display: block;
          height: 24px;
        }

        .gatsby-image-wrapper {
          display: block;
          height: 25px;

          img {
            object-fit: contain !important;
            object-position: left top;
          }
        }

        @media (min-width: 768px) and (max-width: 992px) {
          margin-top: 0;
          margin-left: 0;
          max-width: 80%;
        }
      }
    }

    &-title {
      ${font('display', 'sm', '700')};
      color: ${colors.gray[900]};
      margin-bottom: 12px;
    }

    .subhead {
      ${font('text', 'sm', '400')};
      color: ${colors.gray[700]};
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @media (min-width: 768px) and (max-width: 992px) {
        ${font('text', 'xs', '400')}
        margin-bottom: 8px;
      }
    }
  }
`;
